import { mapActions } from "vuex";

export default {
	name: "page-header",
	data: () => ({
		gradientColor: "transparent",
	}),
	methods: {
		...mapActions(["openMainSearch"]),
	},
	mounted() {
		const bodyBackground = getComputedStyle(document.body).backgroundColor
		const noBackground = "rgba(0, 0, 0, 0)"
		this.gradientColor =
			bodyBackground !== noBackground ? bodyBackground : "#ffffff";
	},
};
