import { mapState, mapActions } from "vuex";
import { rInterval } from "./../../basics/js/utils";
import Cookies from 'js-cookie'

import { ProductType, CylindoViewMode, CookiePrefix } from "./partials/constants";
import { RECONFIGURATOR_GETSTEP_ASYNC, RECONFIGURATOR_GETVARIANT_ASYNC, UPDATE_QUANTITY_ASYNC } from "./../../basics/js/store/static";
import { registerAddToCart, registerRemoveFromCart, registerViewAddon, registerCustomizer } from '../../basics/js/methods/gtmlDataLayer/registerGtmDataLayer'


import BuySectionCore from '../buy-section/buy-section-core';
import BuySection from "../buy-section/buy-section"

import PresentationTile from "./partials/presentation-tile/presentation-tile.vue"
import ZoomOverlay from "./partials/zoom-overlay/zoom-overlay.vue"

import VariantOptions from "./partials/variant-options/variant-options.vue"
import AttributeOptions from "./partials/attribute-options/attribute-options.vue"
import OptionsOverlay from "./partials/options-overlay/options-overlay.vue"
import MaterialGroupInfoOverlay from "./partials/material-group-info-overlay/material-group-info-overlay.vue"

import ConfigurePanels from "./partials/configure-panels/configure-panels.vue"

import QuantityMobile from "./partials/quantity-mobile/quantity-mobile.vue"
import CylindoViewer from "./partials/cylindo-viewer/cylindo-viewer.vue"



export default {
    name: "reconfigurator",
    extends: BuySectionCore,
    components: {
        QuantityMobile,
        BuySection,
        PresentationTile,
        VariantOptions,
        AttributeOptions,
        OptionsOverlay,
        ZoomOverlay,
        MaterialGroupInfoOverlay,
        CylindoViewer,
        ConfigurePanels
    },
    props: {
        menuColor: {
            type: String,
            required: false,
        },
        productNameHeadline: {
            type: String,
            required: true,
        },
        productNameSubheadline: {
            type: String,
            required: false,
        },
        productId: {
            type: String,
            required: true
        },
        cylindoSku: {
            type: String,
            required: false
        },
        cylindoFeatures: {
            type: Object,
            required: false
        },
        productType: {
            type: String,
            required: true
        },
        plannerHref: {
            type: String,
        },
        presentationTiles: {
            type: Array,
            required: true,
        },
        systemText: {
            type: Object,
            required: true,
        },
        variantLists: {
            type: Array,
            required: false,
        },
        variantListsInitIndex: {
            type: Number,
            required: false,
        },
        variantFallbackName:{
            type: String,
            default: '',
            required: false
        },
        attributeLists: {
            type: Array,
            required: false,
        },
        hasAddons: {
            type: Boolean,
            default: false,
        },
        hasVariants: {
            type: Boolean,
            default: false,
        },
        hasAttributes: {
            type: Boolean,
            default: false,
        },
        variantCode: {
            type: String,
            required: true
        },
        baseUrl: {
            type: String,
            required: true
        },
        cartUrl: {
            type: String,
            default: '/cart'
        },
        startFrame: {
            type: Number,
            default: 14,
        },
		presentationRowsDesktopLimit: {
			type: Number,
			default: 3,
		},
        cylindoTexts: {
            type: Object,
            default: () => {
                return {
                    dragToRotate: "Drag to rotate",
                    doubleTapToZoom: "Doubletap to zoom",
                    clickToZoom: "Click to zoom",
                    // tapToInteract: "Tap to interact",
                    messageLabel: "Colors may vary",
                    messageBody: "Please note that colors showed in this configurator is only guiding as colors may vary depending on which screen you are using (and particularly so if your screen isn’t calibrated). For Refine leather and other dyed upholsteries, natural variations can occur and is not possible to reflected in this configurator.",
                    messageButton: "Yes, I got it",
                    tryAR: "Try AR"
                }
            }
        },
        cylindoCrops: {
            type: Array,
            default: ()=>{
                return [
                    {
                        frame: 25,
                        posX: 0,
                        posY: 0,
                        width: 1024,
                        height: 1024,
                        zoom: 1,
                        zoomLrg: 2
                    },
                    {
                        frame: 17,
                        posX: 0,
                        posY: 0,
                        width: 1024,
                        height: 1024,
                        zoom: 1,
                        zoomLrg: 2
                    }
                ]
            }
        },
        additionalLinks: {
            type: Array,
            required: false
        },
        minimumOrderQuantity: {
            type: Number,
            default: 1
        },
        colli: {
            type: Number,
            default: 1
        },
        productName: {
            type: String,
            default: false
        }
    },
    data() {
        return {
			useMobileControlsSnap: true,
            mobileControlsInFocus: false,

            windowScrollAllowed: true,
            windowScrollTop: 0,

            presentationScrollTop: 0,
            presentationCH: 0,
            presentationSH: 0,
            presentationActiveSlideIndex: 0,
            presentationScrollPct: 0, //0-X (top pos of last tile)
			presentationScrollPctAdjusted: 0, //0-1 (adjusted for presentation height)

            presentationTilesConstrained: [],
			presentationTilesCount: 0,
            presentationGalleryTileCount: 0,
            presentationGalleryTileShownCount: 0,

            controlsScrollPct: 0,
            controlsSH: 0,

            presentationCalculatedHeight: 0,
            controlsCalculatedHeight: 0,
            controlsCalculatedHeightSaved: 0,

            cylindoViewMode: null,
            presentationTileARUrl: null,

            quantityStep: this.isColliRestricted ? this.colli : 1,
            currentQuantity: 0,
			showQuantityTimeout: null,

			showAddedTextTimeout: null,
			showingAddedText: false,

            mobileQuantityActive: false,

            userIsTouching: false,

            isReloading: false,

            cylindoFeatureString: ''
        };
    },

    computed: {
        ...mapState({
            isMobile: state => state.isMobile,
            bodyFixed: state => state.bodyFixed,
            windowW: state => state.windowW,
            windowH: state => state.windowH,

            isConfiguring: state => state.reconfigurator.reconfiguratorOpen,

            cylindoAccountId: state => state.cylindoAccountId,
            zoomOverlayOpen: state => state.reconfigurator.zoomOverlayOpen,
            optionsOverlayOpen: state => state.reconfigurator.optionsOverlayOpen,

            stepData: state => state.reconfigurator[RECONFIGURATOR_GETSTEP_ASYNC.stateKey],
            stepLoading: state => state.reconfigurator[RECONFIGURATOR_GETSTEP_ASYNC.loadingKey],
            variantLoading: state => state.reconfigurator[RECONFIGURATOR_GETVARIANT_ASYNC.loadingKey],

            currentStep: state => state.reconfigurator.currentStep,
            totalSteps: state => state.reconfigurator.totalSteps,

            currentVariantFeatures: state => state.reconfigurator.currentVariantFeatures,
            currentCylindoFeatures: state => state.reconfigurator.currentCylindoFeatures,

            currentVariantCode: state => state.reconfigurator.currentVariantCode,
            currentProductId: state => state.reconfigurator.currentProductId,
            currentProductUrl: state => state.reconfigurator.currentProductUrl,
            currentCylindoSku: state => state.reconfigurator.currentCylindoSku,

            finalVariantCode:  state => state.reconfigurator.finalVariantCode,
            finalProductId:  state => state.reconfigurator.finalProductId,

            viewMode: state => state.reconfigurator.cylindoViewMode
        }),
        overallStyle() {
            return `--reconfigurator-presentation-height: ${this.presentationCalculatedHeight};  --reconfigurator-controls-height: ${this.controlsCalculatedHeight}`;
        },
        getActiveVariantName() {
            let foundVariant;
            if ( this.variantLists ) {
                this.variantLists.forEach(list=>{
                    let fV = list.items.find(variant => variant.isActive);
                    if ( fV ) foundVariant = fV;
                })
            }
            return foundVariant ? foundVariant.name : 'NO NAME FOUND';
        },
        showCTAs() {
            return this.isMobile || this.productType === ProductType.CONFIGURABLE || this.productType === ProductType.PLANNABLE || this.hasAddons
        },
        mobileHasLeftCTA() {
            return this.productType === ProductType.CONFIGURABLE || this.productType === ProductType.PLANNABLE
        },
        isMobileFocusedMode() {
            return this.cylindoViewMode === CylindoViewMode.MOBILE_FOCUS
        },
        presentationTileWithAnchor() {
            return this.presentationTiles.find(item=>item.anchor);
        },
        presentationTileWithCylindoViewer() {
            return this.presentationTiles.find(item=>item.isCylindo);
        },
        onlyOneVariant() {
            return (this.variantLists.length === 1 && this.variantLists[0].items.length === 1);
        }
    },
    watch: {
        isMobile() {
            // console.log("isMobile changed. It's now: " + newVal);

            this.closeReConfigurator();
            this.setSectionHeights();
            this.toggleMobileListeners();
        },
        isConfiguring(newVal) {
            if ( newVal && this.isMobile ) {
                this.cylindoViewMode = CylindoViewMode.MOBILE_BLUR;
            }
            this.setSectionHeights();
        },
        isAddedToCart(newVal) {
            if ( newVal ) {
                //when "Added to cart" is visible, show quantity
                this.mobileQuantityActive = true;
                this.currentQuantity += this.quantityStep;

                clearTimeout(this.showQuantityTimeout);
                this.showQuantityTimeout = setTimeout(()=>{
                    this.mobileQuantityActive = false;
					this.showingAddedText = true;
					clearTimeout(this.showAddedTextTimeout);
					this.showAddedTextTimeout = setTimeout(()=>{
						this.showingAddedText = false;
						this.currentQuantity = 0;
						// matching with is-visible animation length
						// matching with is-visible animation length
						setTimeout(()=>this.currentQuantity = 0, 350);
					}, 1000);

                }, 1500);
            }
        },
        currentCylindoFeatures: {
            handler: function(newVal) {
                // console.log("currentCylindoFeatures", newVal)
                this.cylindoFeatureString = this.createFeatureString(newVal);
            },
            deep: true
        }
    },
    methods: {
        ...mapActions([
            "openReConfigurator",
            "closeReConfigurator",

            "openAddonsOverlay",
            "openMosaicSliderOverlay",

            "openToolTip",
            "closeToolTip",

            "setCurrentCylindoSku",
            "setCurrentProductId",
            "setCurrentVariantCode",

            "getStepData",
            "getNextStepData",
            "getPreviousStepData"
        ]),
        setSectionHeights() {
            if ( this.isConfiguring ) {
                if ( this.cylindoViewMode === CylindoViewMode.MOBILE_FOCUS ) {
                    this.controlsCalculatedHeight = 0;
                    this.presentationCalculatedHeight = this.windowH;
                } else if ( this.cylindoViewMode === CylindoViewMode.MOBILE_BLUR ) {
                    if ( this.windowW > 400 ) {
                        this.presentationCalculatedHeight = Math.min(this.windowW, this.windowH/2); //in order for tablet pres to not be too tall
                    } else {
                        this.presentationCalculatedHeight = this.windowH * 0.33;
                    }
                    this.controlsCalculatedHeight = this.windowH - this.presentationCalculatedHeight;
                } else {
                    this.controlsCalculatedHeight = -1;
                }
            } else {
                if ( this.isMobile ) {
                    if ( this.$refs.controlsOptions && this.$refs.controlsHeader ) {
                        this.controlsCalculatedHeight = this.$refs.controlsOptions.offsetHeight + this.$refs.controlsHeader.offsetHeight + 1; //+1 for border
                        this.controlsCalculatedHeightSaved = this.controlsCalculatedHeight;
                    } else {
                        this.controlsCalculatedHeight = this.controlsCalculatedHeightSaved;
                    }
                    this.presentationCalculatedHeight = this.windowH - this.controlsCalculatedHeight;
                } else {
                    this.presentationCalculatedHeight = -1;
                }
            }
            // console.log("UPDATE HEIGHTS", this.isConfiguring, this.cylindoViewMode );
            // console.log("UPDATE HEIGHTS", "CH="+this.controlsCalculatedHeight, "PH="+this.presentationCalculatedHeight);
        },
        getAttributeName: function(attributeIndex) {
            return this.attributeLists[attributeIndex].nameShort;
        },
        getActiveAttributeName: function(attributeIndex) {
            let foundAttr = this.attributeLists[attributeIndex].items.find(attr => attr.isActive);
            if ( foundAttr && foundAttr.name ) return foundAttr.name;
            else return 'NO NAME FOUND';
        },
        toggleMobileListeners: function (force) {
            if ( this.isMobile || force === true) {
                this.$el.addEventListener('touchstart', this.touchListenerStart);
                this.$el.addEventListener('touchend', this.touchListenerEnd);
                this.$el.addEventListener('touchcancel', this.touchListenerCancel);
            } else {
                this.$el.removeEventListener('touchstart', this.touchListenerStart);
                this.$el.removeEventListener('touchend', this.touchListenerEnd);
                this.$el.removeEventListener('touchcancel', this.touchListenerCancel);
            }
        },

        touchListenerStart: function () {
            if ( !this.optionsOverlayOpen && !this.zoomOverlayOpen ) {
                this.userIsTouching = true;
            }
        },
        touchListenerEnd: function () {
            if ( !this.optionsOverlayOpen && !this.zoomOverlayOpen ) {
                this.userIsTouching = false;
                this.testForWindowScrollPosition();
            }
        },
        touchListenerCancel: function () {
            if ( !this.optionsOverlayOpen && !this.zoomOverlayOpen ) {
                // console.log("touch cancel")
                this.userIsTouching = false;
                this.testForWindowScrollPosition();
            }
        },

        resizeListener: function () {
            this.presentationCH = this.$refs.presentation.clientHeight;

            if ( !this.isConfiguring && this.$refs.presentation ) this.presentationSH = this.$refs.presentation.scrollHeight;

            if ( this.isMobile ) {
                this.windowScrollListener();
                this.presentationScrollHandler();
            } else {
                this.mobileControlsInFocus = false;
				this.setSectionHeights();
            }

        },

        windowScrollListener: function () {
            this.closeToolTip();

            if ( this.bodyFixed ) return false;
            if ( !this.isMobile ) return false;
            if ( !this.windowScrollAllowed ) return false;
            if ( this.userIsTouching ) return false;

            // console.log("WIN SCROLL")
            // if ( !this.$refs.controls ) return false;

            this.testForWindowScrollPosition();
        },
        testForWindowScrollPosition() {

            let scroll = window.scrollY < 0 ? 0 : window.scrollY;
            let cOT = this.$refs.controls.offsetTop;
            let thr = this.windowH * 0.75;

            // console.log("window scroll", scroll, cOT, thr);

            if ( scroll > this.windowScrollTop && scroll > cOT - thr && !this.mobileControlsInFocus ) {
                // if scroll direction is down AND the user has scrolled 75% AND the controls are not already in focus
                this.focusMobileControls();
            } else if ( scroll < this.windowScrollTop && scroll < thr && this.mobileControlsInFocus ) {
                // if scroll direction is up AND the user has scrolled 75% AND the controls are not already in focus
                this.defocusMobileControls();
            }
            this.windowScrollTop = scroll;
        },
        presentationScrollHandler: function () {
            this.presentationSH = this.$refs.presentation.scrollHeight;

            if ( this.isConfiguring ) return false;
            if ( this.bodyFixed ) return false;
            if ( !this.isMobile ) return false;
            if ( !this.windowScrollAllowed ) return false;
            if ( this.userIsTouching ) return false;


            let scroll = this.$refs.presentation.scrollTop < 0 ? 0 : this.$refs.presentation.scrollTop;
            if ( scroll === this.presentationScrollTop ) return false;

			// calculate scrollPct based on scroll position and presentation height
            this.presentationScrollPct = scroll / this.presentationSH;
            this.presentationScrollPctAdjusted = scroll / (this.presentationSH - this.presentationCalculatedHeight);


            // console.log("real", Math.round(this.presentationScrollPct*100)/100);

			// add 50% to scrollPct, to make sure the active slide is always the one that is most visible
            this.presentationScrollPct += (this.presentationCalculatedHeight/this.presentationSH)/2;

			// console.log("adjusted", Math.round(this.presentationScrollPctAdjusted*100)/100);

			// calculate active slide index based on scrollPct and number of slides
            this.presentationActiveSlideIndex = Math.round(this.presentationScrollPct * (this.presentationTilesCount-1));

			// make sure active slide index is never higher than the number of slides
			this.presentationActiveSlideIndex = this.presentationActiveSlideIndex > this.presentationTilesCount-1 ? this.presentationTilesCount-1 : this.presentationActiveSlideIndex;

            // console.log(this.presentationTilesCount, this.presentationActiveSlideIndex)



			console.log("scrolling down?", scroll > this.presentationScrollTop)
            if ( scroll > this.presentationScrollTop && this.presentationScrollPctAdjusted > 1.02 ) {
				// if scroll direction is down AND the user has scrolled BEYOND bottom of presentation
                this.focusMobileControls();
            }


			this.presentationScrollTop = scroll;
        },


        ctaClickHandler: function (type) {
            switch (type) {
                case 'configure':
                    this.startConfigureClickHandler();
                    registerCustomizer(this.productNameHeadline+this.productNameSubheadline);
                    break;

                case 'addons':
                    //open addons overlay
                    this.openAddonsOverlay();
                    registerViewAddon(this.productNameHeadline+this.productNameSubheadline);
                    break;

                case 'anchor':
                    //scroll to tile
                    if ( this.$refs.presentation ) {
                        let el = this.$refs.presentation.querySelector('#'+this.presentationTileWithAnchor.anchor);
                        if ( el ) el.scrollIntoView({behavior: 'smooth'});
                    }
                    break;

                case 'ar':
                    //show AR
                    // console.log("presentationTileARUrl", this.presentationTileARUrl)
                    window.open(this.presentationTileARUrl);
                    break;
            }
        },

        focusMobileControls() {
			if (!this.useMobileControlsSnap) return false;

			if (!this.mobileControlsInFocus) {
                this.mobileControlsInFocus = true;
                if ( this.isMobile ) {
                    this.windowScrollAllowed = false;
                    window.scrollTo({top: this.$refs.controls.offsetTop, behavior: "smooth"});

                    setTimeout(()=>{
                        window.scrollTo({top: this.$refs.controls.offsetTop, behavior: "smooth"});
                        this.windowScrollAllowed = true;
                    }, 500);
                }
            }



        },
        defocusMobileControls() {
			if (!this.useMobileControlsSnap) return false;

			if (this.mobileControlsInFocus) {
                this.mobileControlsInFocus = false;
                if ( this.isMobile ) {

                    this.windowScrollAllowed = false;
                    window.scrollTo({top: 0, behavior: "smooth"});
                    setTimeout(()=>{
                        window.scrollTo({top: 0, behavior: "smooth"});
                        this.windowScrollAllowed = true;
                    }, 500);
                }
            }
        },
        configuratorModeShiftHandler( mode ) {
            this.cylindoViewMode = mode;
            this.setSectionHeights();
        },
        configuratorHasARHandler( url ) {
            this.presentationTileARUrl = url;
        },


        registerInDataLayer(clickType, quantityChange){
            const product = this.product;

            if (clickType === 'plus') {
                registerAddToCart(product.name, product.variantName, product.sku, product.unitPrice, quantityChange);
            } else {
                registerRemoveFromCart(product.name, product.variantName, product.sku, product.unitPrice, quantityChange);
            }
        },


        onQuantityInvalid(q) {
            console.log("onQuantityInvalid",q)
            // TODO: handle return value?
        },
        onQuantityUpdate({quantity, quantityChange, actionType}){ // COMMENT: Gets trigged by quantity-component's emitted custom-event

			clearTimeout(this.showQuantityTimeout);
			clearTimeout(this.showAddedTextTimeout);

            console.log('onQuantityUpdate', "q="+quantity, "c="+quantityChange, "moq="+this.minimumOrderQuantity);

            if ( quantity && quantity > -1 ) {
				// TEST
				if ( quantity < this.minimumOrderQuantity ) {
					quantity = this.minimumOrderQuantity;
				}

				this.currentQuantity = quantity;

                if(this.shouldQuantityTriggerUpdate) {
                    const url = this.cartUrl;
                    const body = {
                        code: this.sku,
                        quantity: quantity
                    }
                    this.$store.dispatch(UPDATE_QUANTITY_ASYNC.action, { url, body });
                }
            }
            if(this.shouldQuantityTriggerUpdate && quantity && quantity > -1) {
                this.registerInDataLayer(actionType, quantityChange);
            }
            // TODO: handle return value?




            if ( quantity === 0 ) {
                this.mobileQuantityActive = false;
                this.currentQuantity = 0;
            }


            //hide quantity, if user is not not interacting
            clearTimeout(this.showQuantityTimeout);
			this.showQuantityTimeout = setTimeout(()=>{
				this.mobileQuantityActive = false;
				this.showingAddedText = true;
				clearTimeout(this.showAddedTextTimeout);
				this.showAddedTextTimeout = setTimeout(()=>{
					this.showingAddedText = false;
					// matching with is-visible animation length
					setTimeout(()=>this.currentQuantity = 0, 350);
				}, 1000);

			}, 1500);
        },

        parsePresentationTiles() {
			this.presentationTilesCount = this.presentationTiles.length;

			let tilesConstrained = [];
            let tileShownCount = 0;
            let tileImageGalleryCount = 0;
            let tileImageGalleryShownCount = 0;

            for (let i=0; i<this.presentationTilesCount; i++) {
                let tile = this.presentationTiles[i];

                if ( tile.isDouble ) {
					// if tile starts at odd index, add 3 to tileShownCount, otherwise add 2
					tileShownCount += (i+1)%2 === 0 ? 3 : 2;
                } else {
                    tileShownCount += 1;
                }
                if ( tileShownCount <= this.presentationRowsDesktopLimit*2 ) {
					tilesConstrained.push(tile);

                    if ( !tile.isCylindo && !tile.isContent ) {
						tileImageGalleryShownCount++;
                    }
                }

                if ( !tile.isCylindo && !tile.isContent ) {
					tileImageGalleryCount++;
                }

				if ( tileShownCount === this.presentationRowsDesktopLimit*2 ) break;
            }


            this.presentationTilesConstrained = tilesConstrained;

            this.presentationGalleryTileCount = tileImageGalleryCount;
            this.presentationGalleryTileShownCount = tileImageGalleryShownCount;

			// console.log("out of " +this.presentationTilesCount+" " +this.presentationTilesConstrained.length + " are shown in "+this.presentationRowsDesktopLimit + " rows");

        },

        startConfigureClickHandler: function () {

            window.scrollTo({ top: 0 }); //to ensure no overflow hidden on body visual glitches
            this.toggleMobileListeners(false);
            this.openReConfigurator();
            this.defocusMobileControls();

            this.getStepData(0);

            // window.addEventListener("beforeunload", this.beforeUnloadHandler.bind(this), {capture: true});
        },
        cancelConfigureClickHandler: function () {
            this.closeReConfigurator();


            // window.removeEventListener("beforeunload", this.beforeUnloadHandler.bind(this), {capture: true});
        },

        beforeUnloadHandler: function (event) {
            event.preventDefault();
            return event.returnValue = this.systemText.reloadText;
        },

        finishConfigureHandler: function() {

            let currentSaved = Cookies.get(CookiePrefix+this.finalProductId);
            if ( currentSaved && currentSaved.indexOf(this.finalVariantCode) === -1 ) {
                Cookies.set(CookiePrefix+this.finalProductId, `${currentSaved}|${this.finalVariantCode}`, { expires: 60 });
            } else {
                Cookies.set(CookiePrefix+this.finalProductId, `${this.finalVariantCode}`, { expires: 60 });
            }

            // this.closeReConfigurator();


            // window.removeEventListener("beforeunload", this.beforeUnloadHandler.bind(this), {capture: true});
            //window.open(this.currentProductUrl,"_self");

            this.isReloading = true;
            location.href = this.currentProductUrl;
        },
        createFeatureString: function (features) {

            // create feature string for url, such as:
            // feature=SEAT:WOOD%20AND%20PLASTIC%20COMPOSITE%20GREY&feature=TEXTILE:HALLINGDAL%20702&feature=BASE:LACQUERED%20OAK%20GREY

            let s = '';
            if ( features ) {
                Object.entries(features).forEach(entry => {
                    if ( entry[1] ) s += `feature=${entry[0]}:${encodeURI(entry[1].toUpperCase())}&`;
                });
            }
            return s;
        }
    },
    mounted() {
        if ( window ) {
            window.addEventListener('scroll', this.windowScrollListener);
            window.addEventListener('resize', this.resizeListener);

            setTimeout(this.setSectionHeights, 1000);
        }
        if ( this.$refs.presentation ) {
            rInterval(this.presentationScrollHandler, 100);
        }

        this.cylindoFeatureString = this.createFeatureString(this.cylindoFeatures);

        this.setCurrentCylindoSku(this.cylindoSku);
        this.setCurrentProductId(this.productId);
        this.setCurrentVariantCode(this.variantCode);

        this.setSectionHeights();
        this.toggleMobileListeners();

        this.parsePresentationTiles();



		//Auto open configurator if "configure=true"
		const urlParams = new URLSearchParams(window.location.search);
		if (urlParams.get('configure')) this.startConfigureClickHandler();

    }
}

