export default {
	name: 'expandable',
	data() {
		return {
			expanded: false,
			animating: false
		}
	},
	methods: {
		expandButtonHandler(){
			if ( !this.expanded ) {
				this.animating = true
				setTimeout(()=>{
					this.expanded = true
				}, 30)
			} else {
				this.expanded = false
				setTimeout(()=>{
					this.animating = false
					this.expanded = false
				}, 200)
			}
		}
	}
};
